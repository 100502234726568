<template>
  <FileRepositoriesTable/>
</template>
<script>

import FileRepositoriesTable from '@/views/ziqni/fileRepositories/FileRepositoriesTable';

export default {
  components: {
    FileRepositoriesTable
  },
  props: {}
};
</script>

