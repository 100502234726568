<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    :displayFields="displayFields"
    model="fileRepositories"
  />
</template>
<script>
import { fileRepositories } from '@/config/descriptions/fileRepositories';
import { fileRepositoriesTexts } from '@/config/pageTexts/fileRepositories.json';

export default {
  name: 'FileRepositoriesTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'fileRepositories/fileRepositories',
          fields: 'fileRepositories/fields',
          sortableFields: 'fileRepositories/sortableFields',
          pages: 'fileRepositories/pages',
          loading: 'fileRepositories/loading',
          resultCount: 'fileRepositories/resultCount',
          totalRecords: 'fileRepositories/totalRecords',
          originalFields: 'fileRepositories/originalFields',
          searchableAllFields: 'fileRepositories/searchableAllFields',
          success: 'fileRepositories/success',
          message: 'fileRepositories/message',
        },
        actions: {
          fields: 'fileRepositories/handleFields',
          delete: 'fileRepositories/handleDeleteFileRepositories',
          reset: 'fileRepositories/handleClearFilter',
          query: 'fileRepositories/handleGetFileRepositoriesByQuery',
        },
      },
      settings: {
        describe: { ...fileRepositories },
        page: { ...fileRepositoriesTexts },
      },
      route: {
        create: 'CreateFileRepository',
        edit: 'EditFileRepository',
        preview: 'PreviewFileRepository',
      },
      displayFields: ['select', 'id', 'name', 'constraints', 'tags', 'actions'],
    };
  },
};
</script>